import axios from 'axios';
import {ServerResponse} from 'http';
import cookie from 'cookie';
import {config} from './config';
import {getCountry, getLanguage} from './util';
import qs from 'qs';

export const api = axios.create({
  baseURL: config.apiURL,
  headers: process.env.NEXT_SSR_TOKEN ? {'X-SSR-TOKEN': process.env.NEXT_SSR_TOKEN} : {},
  paramsSerializer: (params) => {
    return qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false,
    });
  },
});

api.interceptors.response.use(
  (response) => {
    // Throw if error_status available
    if (response.data.error_status) {
      throw {
        response: {
          data: JSON.parse(response.data.error_status.http_body),
          status: response.data.error_status.http_status_code,
        },
        isAxiosError: true,
      };
    }

    return response;
  },
  (error) => {
    if (axios.isAxiosError(error)) {
      if (error.response?.data.error_status) {
        throw {
          response: {
            data: JSON.parse(error.response.data.error_status.http_body),
            status: error.response.data.error_status.http_status_code,
          },
          isAxiosError: true,
        };
      }
    }
    throw error;
  },
);

export function setApiToken(token: string) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export function clearApiToken() {
  delete api.defaults.headers.common.Authorization;
}

export function setApiLocale(locale: Locale) {
  // Someday we'll unify both headers backend wise
  // Currently X-Locale is used for language
  // X-REGION is used for country
  api.defaults.headers.common['X-Locale'] = getLanguage(locale);
  api.defaults.headers.common['X-REGION'] = getCountry(locale);
}

export function setCookies(accessToken: string, res: ServerResponse) {
  return res.setHeader('Set-Cookie', [
    cookie.serialize('accessToken', accessToken, {
      httpOnly: true,
      secure: config.appEnv !== 'development',
      sameSite: 'none',
      maxAge: 60 * 60 * 24 * 7,
      path: '/',
    }),
    cookie.serialize('region', JSON.parse(new Buffer(accessToken.split('.')[1], 'base64').toString()).region, {
      httpOnly: true,
      sameSite: 'none',
      maxAge: 60 * 60 * 24 * 7,
      path: '/',
    }),
  ]);
}

export function removeCookies(res: ServerResponse) {
  return res.setHeader('Set-Cookie', [
    cookie.serialize('accessToken', '', {
      httpOnly: true,
      secure: config.appEnv !== 'development',
      sameSite: 'none',
      maxAge: 0,
      path: '/',
    }),
    cookie.serialize('region', '', {
      httpOnly: true,
      sameSite: 'none',
      maxAge: 60 * 60 * 24 * 7,
      path: '/',
    }),
  ]);
}
