import {GoogleAdUnit, GoogleAdUnitPath} from './types';

export const GOOGLE_AD_SLOTS: Record<GoogleAdUnitPath, GoogleAdUnit> = {
  '/22892919920/EKSAB-MobileLeaderboard': {
    path: '/22892919920/EKSAB-MobileLeaderboard',
    size: [
      [320, 100],
      [320, 50],
    ],
  },
  '/22892919920/EKSAB-Leaderboard': {
    path: '/22892919920/EKSAB-Leaderboard',
    size: [728, 90],
  },
};
