import {useBreakpointValue} from '@chakra-ui/react';
import {GoogleAd} from './GoogleAd';
import {useEffect, useState} from 'react';
import {GoogleAdUnitPath} from './types';

export type GoogleBannerAdProps = {
  slotId: string;
  size?: 'wide' | 'narrow';
};

export function GoogleBannerAd({slotId, size}: GoogleBannerAdProps) {
  const calculatedSize = useBreakpointValue<GoogleBannerAdProps['size']>({base: 'narrow', lg: 'wide'});
  if (size === undefined) {
    size = calculatedSize;
  }

  const [adUnitPath, setAdUnitPath] = useState<GoogleAdUnitPath>('/22892919920/EKSAB-MobileLeaderboard');

  useEffect(() => {
    setAdUnitPath(size === 'wide' ? '/22892919920/EKSAB-Leaderboard' : '/22892919920/EKSAB-MobileLeaderboard');
  }, [size]);

  return <GoogleAd adUnitPath={adUnitPath} slotId={slotId} />;
}
