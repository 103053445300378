import {create} from 'zustand';
import {GoogleAdUnitPath} from './types';

type State = {
  definedSlots: {[slotId: string]: {path: GoogleAdUnitPath; slot: any}};
  addSlot(slotId: string, adUnitPath: GoogleAdUnitPath, slot: any): void;
  getSlot(slotId: string): any;
  removeSlot(slotId: string): void;
  resetSlots(): void;
  isSlotDefined(slotName: string): boolean;
  hasAnySlotDefined(): boolean;
};

const initialState: Pick<State, 'definedSlots'> = {
  definedSlots: {},
};

export const useGoogleAdStore = create<State>((set, get) => ({
  ...initialState,
  addSlot: (slotId: string, adUnitPath: GoogleAdUnitPath, definedSlot: any) => {
    set((prevState) => {
      return {
        ...prevState,
        definedSlots: {
          ...prevState.definedSlots,
          [slotId]: {path: adUnitPath, slot: definedSlot},
        },
      };
    });
  },
  getSlot: (slotId: string) => {
    return get().definedSlots[slotId];
  },
  removeSlot: (slotId: string) => {
    set((state) => {
      const newState = {...state};
      delete newState.definedSlots[slotId];
      return newState;
    });
  },
  resetSlots: () => set(() => ({definedSlots: {}})),
  isSlotDefined: (slotName: string) => !!get().definedSlots[slotName],
  hasAnySlotDefined: () => Object.keys(get().definedSlots).length > 0,
}));
