import {CloseIcon} from '@chakra-ui/icons';
import {Center} from '@chakra-ui/react';

export default function CloseIframeButton() {
  return (
    <Center
      as="button"
      cursor="pointer"
      rounded="full"
      boxSize="12"
      bg="#131313"
      onClick={() => {
        window.parent?.postMessage({event: 'trivia.closed', payload: {}}, '*');
      }}
      position="absolute"
      top="1"
      right="1"
      zIndex="10"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.2)"
    >
      <CloseIcon color="white" />
    </Center>
  );
}
