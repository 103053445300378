import {useEffect} from 'react';
import {useGoogleAdStore} from './useGoogleAdStore';
import {useRouter} from 'next/router';

export function useInitializeGoogleAds() {
  const router = useRouter();
  const {definedSlots} = useGoogleAdStore();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.googletag = window.googletag || {cmd: []};

      googletag?.cmd.push(() => {
        googletag?.pubads().disableInitialLoad();
        googletag?.pubads().collapseEmptyDivs();
        googletag?.pubads().enableSingleRequest();
        googletag?.enableServices();
      });
    }
  }, []);

  useEffect(() => {
    googletag?.cmd.push(() => {
      // Request ads for all ad slots defined up to this point.
      //
      // In many real world scenarios, requesting ads for *all*
      // slots is not optimal. Instead, care should be taken to
      // only refresh newly added/updated slots.
      const slots = Object.values(definedSlots).map(({slot}) => slot);
      googletag?.pubads().refresh(slots);
    });
  }, [router.asPath]);
}
