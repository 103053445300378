import {useEffect} from 'react';
import {GOOGLE_AD_SLOTS} from './const';
import {GoogleAdUnitPath} from './types';
import {useGoogleAdStore} from './useGoogleAdStore';
import {Flex} from '@chakra-ui/react';
import {GoogleBannerAdProps} from './GoogleBannerAd';

type GoogleAdProps = GoogleBannerAdProps & {
  adUnitPath: GoogleAdUnitPath;
};

export function GoogleAd({adUnitPath, slotId}: GoogleAdProps) {
  const adUnit = GOOGLE_AD_SLOTS[adUnitPath];

  const {addSlot, getSlot, removeSlot} = useGoogleAdStore();

  useEffect(() => {
    if (typeof window !== 'undefined' && window.googletag) {
      // Register the slot with GPT when the component is loaded.
      googletag?.cmd.push(() => {
        const definedSlot = googletag?.defineSlot(adUnit.path, adUnit.size, slotId);
        if (definedSlot) {
          definedSlot.addService(googletag?.pubads());
          googletag?.display(definedSlot);
          googletag?.pubads().refresh([definedSlot]);
          addSlot(slotId, adUnitPath, definedSlot);
        }
      });

      // // Clean up the slot when the component is unloaded.
      return () => {
        googletag?.cmd.push(() => {
          const definedSlot = getSlot(slotId);
          if (definedSlot) {
            googletag?.destroySlots([definedSlot.slot]);
            removeSlot(slotId);
          }
        });
      };
    }
  }, [adUnit]);

  const firstSlotSize: [number, number] = Array.isArray(adUnit.size[0])
    ? adUnit.size[0]
    : (adUnit.size as [number, number]);

  // Create the ad slot container.
  return (
    <Flex
      key={adUnitPath}
      justifyContent="center"
      id={slotId}
      style={{minWidth: firstSlotSize[0], minHeight: firstSlotSize[1]}}
    />
  );
}
