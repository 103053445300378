import {create} from 'zustand';

interface EmbeddedStoreActions {
  setIsEmbedded: (isEmbedded: boolean) => void;
}

type EmbeddedState = {
  isEmbedded: boolean;
};

type EmbeddedStore = EmbeddedState & EmbeddedStoreActions;

const initialState: EmbeddedState = {
  isEmbedded: false,
};

export const useEmbedded = create<EmbeddedStore>((set) => ({
  ...initialState,
  setIsEmbedded: (isEmbedded) => set({isEmbedded}),
}));
